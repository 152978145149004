import React, { useEffect, useState } from 'react'
import { ApiKey } from '../../common/Apikey';
import $, { event } from 'jquery';
import { toast } from 'react-toastify';

function EditAddonModel(props) {
    console.log(props)

    const [AddonList, setAddonList] = useState(props.AddonData || []);
    const [MaxAddon, setMaxAddon] = useState(props.MaxCountAddon || 0);

    // [{"addon_id": 1,"count":2},{"addon_id": 2,"count":3}]

    const [cartItems, setCartItems] = useState(props?.SelectAddonData || []);

    // console.log(cartItems,'cartItems')

    const plus = (item) => {
        const existingItemIndex = cartItems.findIndex((cartItem) => cartItem.addon_id === item.addon_id);

        if (existingItemIndex !== -1) {
            const newCartItems = [...cartItems];

            // check max count
            const matchedItem = AddonList.find(item => item.id === newCartItems[existingItemIndex].addon_id);
            if(newCartItems[existingItemIndex].count == matchedItem.max_number){
                toast.error('Maximum count reached.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }else{
                newCartItems[existingItemIndex].count += 1;
                setCartItems(newCartItems);
            }

        } else {
            if (cartItems.length == MaxAddon) {
                toast.error('Maximum Addon Services selected.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                setCartItems([...cartItems, { ...item, count: 1 }]);
            }
        }
    };

    const minus = (itemId) => {
        const newCartItems = cartItems.map((item) => {
            if (item.addon_id === itemId.addon_id) {
                const updatedCount = item.count >= 0 ? item.count - 1 : 0;
                return { ...item, count: updatedCount };
            } else {
                return item;
            }
        }).filter((item) => item.count > 0);

        setCartItems(newCartItems);
    };

    const confirmAddon = () => {
        props.getAddon(cartItems)
        setTimeout(() => {
            $('#AddonClose').click()
        }, 1000);
    }

    const modelClose = () => {
        // setCartItems([])
        props.getAddon('close')
        setTimeout(() => {
            $('#AddonClose').click()
        }, 1000);
    }

    // console.log('AddonList:', AddonList);
    // console.log('MaxAddon:', MaxAddon);
    // console.log('cartItems:', cartItems);
    // console.log('SelectAddonData:', props.SelectAddonData)

    return (
        <>
            <div className="modal fade" id="AddonData" aria-labelledby="AddonData" aria-hidden="true" data-bs-backdrop="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header megHeader">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Vimo Services</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" id="AddonClose" aria-label="Close" onClick={modelClose}></button>
                        </div>
                        <div className="modal-body">
                            <div className='AddonTextBG'>
                                <p>You can able to add maximum {MaxAddon} add on services from below</p>
                            </div>
                            <div className='AddonDiv'>
                                {AddonList.length > 0 ? AddonList.map((addon, key) => (
                                    <div className="accordion-item mb-3 addon-list" key={key}>
                                        <div className="accordion-header">
                                            <div className='row'>
                                                <div className='col-2'>
                                                    <img src={ApiKey.main_name + '/' + addon.image} className="Addon-image" />
                                                </div>
                                                <div className='col-5'>
                                                    <p className="addon-p">{addon.title}</p>
                                                </div>
                                                <div className='col-5'>
                                                    <div className="addon-buttons d-flex">
                                                        <p className='airport-decrement-btn' id="DecP" onClick={() => minus({ addon_id: addon.id, addon_name: addon.title })}> &nbsp; - &nbsp;</p>
                                                        {cartItems.length > 0 ? (
                                                            <span className="number_padd" id="wheelchair-input">
                                                                {cartItems
                                                                    .filter(item => item.addon_id === addon.id)
                                                                    .map((item, key) => item.count)
                                                                    .reduce((sum, count) => sum + count, 0)
                                                                }
                                                            </span>
                                                        ) : (
                                                            <span className="number_padd" id="wheelchair-input">0</span>
                                                        )}



                                                        <p className='airport-increment-btn' id="DecP" onClick={() => plus({ addon_id: addon.id, addon_name: addon.title })}> &nbsp; + &nbsp;</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p>Max {addon.max_number} Counts</p>
                                                </div>
                                                <div className='col-4'>
                                                    <p>SGD {addon.price}/Count</p>
                                                </div>
                                                <div className='col-4'>
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="true" aria-controls={`collapse${key}`}><i className='fa fa-circle-info addon-info'></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div id={`collapse${key}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample" style={{ padding: '10px' }}>
                                            <div className="accordion-body" style={{ textAlign: 'left' }}>
                                                <p className='p-0 m-0'>Description:</p>
                                                <p className="text-left" dangerouslySetInnerHTML={{ __html: addon.description }} />
                                            </div>
                                        </div>
                                    </div>
                                )) : <></>}
                            </div>
                            <div className='d-flex justify-content-center'>
                                <button type="button" className="btn rounded-pill VerBtn" data-bs-dismiss="modal" aria-label="Close" onClick={confirmAddon}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditAddonModel