// demo
// export const ApiKey = {
//     google_map_key: "AIzaSyDpQxPYE0oeS-peTg9uDdw0Kx2rPTqCfto",
//     stripekey: "pk_test_51Lx1xFAf6IJVLww3dFZM2EqoeGjadALMgD7lGuANMLF82xvwykJGRDSbJLjU38xlbILwSsMTU9q6siVdBPdO265q00LLRHjlF3",    
//     api_name: "http://vimoservices.uplogictech.com/api/v14/",
//     main_name: "https://vimoservices.uplogictech.com"
// }

// live
export const ApiKey = {
    google_map_key: "AIzaSyDpcrog4tUreJObXTnJdhiXjzxJc4KizpM",
    stripekey: "pk_live_yXUOJIStZEwenPy9Lw0mjglM",
    api_name: "https://backend.vimoservices.sg/api/v14/",
    main_name: "https://backend.vimoservices.sg" 
}


export const ApiCall = async (url = '', data = {}, method = 'Post') => {
    var formBody = [];
    for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const response = fetch(ApiKey.api_name + url, {
        method: method,
        // Auth key here 
        headers: {
            'Authorization': '',
            'Content-Type': 'application/x-www-form-urlencoded'

        },

        body: formBody
    })
    return (await response).json()

}