import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./component/Home";
import { Provider } from "react-redux";
import store, { persist } from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import VerifyOtp from "./component/EditBooking/VerifyOtp";
import NotFound from "./component/EditBooking/NotFound";
import NoEdit from "./component/EditBooking/NoEdit";
import EditBooking from "./component/EditBooking/EditBooking";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persist}>
        <BrowserRouter>
          <Routes>
            <Route path="/booking" element={<Home />} />
            <Route path="/BookverifyOtp/:id" element={<VerifyOtp />} />
            <Route path="/notFound" element={<NotFound />} />
            <Route path="/noEdit" element={<NoEdit />} />
            <Route path="/Booking_details" element={<EditBooking />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
